<template>
  <AccountSettingConfig />
</template>
<script>
import AccountSettingConfig from '@/views/account-setting/AccountSettingConfig.vue'

export default {
  components: {
    AccountSettingConfig,
  },
  setup() {
    //
  },
}
</script>
